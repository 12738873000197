import React, {Fragment, useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import WalletButton from '../Wallet/WalletButton';

import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CardContent from '@mui/material/CardContent';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const MobileTransferList = () => {

  
    
  const [barstate, setBarState] = useState({
    top: false,
    bottom: false,
    bottom: false,
    right: false,
  });

const [selectedTransfer, setSelectedTransfer] = useState(null);

const togglebottomBar = () => {
    const event = new Event("toggleWithdrawalBar");
    window.dispatchEvent(event);
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setBarState({ ...barstate, [anchor]: open });
  };



  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 4);
    let first3 = text.substring(0,3);
    return first3+'XXX'+last6;
  }

    const user = useSelector((state) => state.user.user);

    const debits = useSelector((state) => state.history.debits);
    const credits = useSelector((state) => state.history.credits);

    const fundings = useSelector((state) => state.wallet.fundings);


    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: user.currency, currencySign: 'standard' }).format(amount);
    const formatDate = (date) => new Date(date).toLocaleString('en-GB', {
      hour12: false,
    });
    const allTransferList = debits.concat(credits);
    const finalList = fundings.concat(allTransferList);
    const combinedTransferList = finalList.sort((b, a) => {
      return (a.created_at).localeCompare((b.created_at))
  })

    useEffect(() => {
      window.addEventListener(
        "toggleWithdrawalBar",
        (e) => {
         setBarState({ ...barstate, ['bottom']: true });
        },
        false
      );
      
    }, []);

    
  return (
    <Fragment>
          <Grid display={{md: 'none'}}  item xs={12} md={8}>
{combinedTransferList.length > 0 &&
    combinedTransferList.map((transaction, index) => (
        index <= 2 &&

        <ListItem sx={{cursor: 'pointer'}} onClick={()=>{
          setSelectedTransfer(transaction);
          togglebottomBar();
        }
        
        
        
        } key={transaction.id} alignItems="flex-start">
                      
        <ListItemText
          primary={transaction.description}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="caption" display="block"
                color={(parseInt(transaction.user_id) === parseInt(user.id) || parseInt(transaction.amoaunt) < 1 )? 'error' : '#0fb339'}
                
              >
                <Typography variant="caption" sx={{color: 'gray'}}>
                 {formatDate(transaction.created_at)}
                </Typography>
              </Typography>
            </React.Fragment>
          }
        />
          
          <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'left', alignItems: 'left'}}>
              <Typography color={(parseInt(transaction.user_id) === parseInt(user.id) || parseInt(transaction.amoaunt) < 1 )? 'error' : '#0fb339'} mt={1} edge="end">
              {formatPrice(transaction.amount || transaction.amount)}
              </Typography>

              <Typography edge="end">
              {transaction.status.toLowerCase() === 'approved'? 'successful': transaction.status}
              </Typography>
          </Box>
      </ListItem>

))}
</Grid>

<SwipeableDrawer
      
      anchor={`bottom`}
      open={barstate[`bottom`]}
      
      onClose={toggleDrawer(`bottom`, false)}
      onOpen={toggleDrawer(`bottom`, true)}> 

      <Card sx={{ borderRadius: '9px', height: '100%'}}>

        <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="h6">
        Transaction Details
        </Typography>
          {selectedTransfer !== null &&
          
          
        <CardContent>
        
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Transfer Amount </Typography>
                            <Typography py={1} variant="caption" >{formatPrice(selectedTransfer.amount)}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Transfer Type</Typography>
                            
                            <Typography py={1} variant="caption" >{selectedTransfer.transfer_type}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Account Number</Typography>
                            
                            <Typography py={1} variant="caption" >{selectedTransfer.account_number !== null ? selectedTransfer.account_number : selectedTransfer.interAccountNumber}</Typography>
                          </Box>
                          <Divider/>

                          {selectedTransfer.transfer_type === 'Interbank Transfer' && 
                          <Fragment>
                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Routine Number</Typography>
                            
                            <Typography py={1} variant="caption" >{selectedTransfer.routineNumber}</Typography>
                          </Box>
                          <Divider/>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Bank Name</Typography>
                            
                            <Typography py={1} variant="caption" >{selectedTransfer.bankName}</Typography>
                          </Box>
                          <Divider/>
                          </Fragment>
                        }

                          {selectedTransfer.receiver_id === null &&
                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Receiver Email</Typography>
                            
                            <Typography py={1} variant="caption" >{selectedTransfer.receiverEmail}</Typography>
                          </Box>
                          }

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Description</Typography>
                            
                            <Typography py={1} variant="caption" >{selectedTransfer.description}</Typography>
                          </Box>

                          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                            <Typography py={1} variant="caption" >Transaction Date</Typography>
                            
                            <Typography py={1} variant="caption" >{formatDate(selectedTransfer.created_at)}</Typography>
                          </Box>
       </CardContent>

          }

      </Card>
    </SwipeableDrawer>

</Fragment>
  )
}

export default MobileTransferList
