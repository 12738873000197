import * as React from 'react';
import { Fragment, useEffect } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export default function Offline() {
  
       
  return (
        <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <HomeIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Home`} />
                  </ListItemButton>
              </ListItem>
            </Link>            
          </List>

          <Divider />
          <List>
          {/* <Link  to='/subscriptions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <CardMembershipIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Subscription`} />
                  </ListItemButton>
              </ListItem>
            </Link> */}

            

            <Link  to='/login' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <LoginIcon/>
                  </ListItemIcon>
                  <ListItemText  primary={`Login`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Divider/>
            <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Live Support`} />
                </ListItemButton>
            </ListItem>
          </Link>

          <ListItem  disablePadding>
          <div id="trans_container" style={{height: '20px', width: '150px'}}>
                  <span id="google_translate_element">
                    TRANSLATING NOW
                  </span>
                </div>       
            </ListItem>

          </List>
         
    </Fragment>
  );
}